<template>
  <div class="d-flex">
    <div class="breadcrumb-item">
      <router-link v-if="to" :to="typeof to === 'object' ? to : { name: to }" class="text-white text-hover-primary">
        {{ title }}
      </router-link>
      <span v-else class="text-white opacity-50">{{ title }}</span>
    </div>
    <div v-if="to" class="breadcrumb-item pe-2">
      <span class="text-white">/</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
    },
  },
};
</script>
