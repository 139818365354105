<template>
  <div>
    <div class="toolbar py-5 py-lg-15">
      <div class="container-xxl d-flex flex-stack flex-wrap align-items-start">
        <div class="page-title d-flex flex-column me-3">
          <h1 class="text-white">Archive</h1>

          <px-new-breadcrumbs v-if="breadcrumbs.length" :breadcrumbs="breadcrumbs" />
        </div>
      </div>
    </div>

    <div class="container-xxl pb-10">
      <div class="content flex-row-fluid">
        <div class="mb-7">
          <h3 class="text-white mb-6">Contracts & Proposals</h3>
          <pxs-proposals :excludedHeaders="['construction', 'address', 'city']" :is-archived="true" />
        </div>

        <div>
          <h3 class="mb-6">Change Orders</h3>
          <pxs-change-orders :excluded-headers="['title', 'project', 'city']" :is-archived="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import PxNewBreadcrumbs from '@/components/elements/PxNewBreadcrumbs';

import PxsProposals from '@/sections/PxsProposals';
import PxsChangeOrders from '@/sections/PxsChangeOrders';

export default {
  components: {
    PxNewBreadcrumbs,
    PxsProposals,
    PxsChangeOrders,
  },
  data() {
    return {
      breadcrumbs: [],
    };
  },
  computed: {
    ...mapGetters({
      item: 'projects/item',
    }),
    projectId() {
      return this.$route.params.id;
    },
    address() {
      return this.item?.attributes?.address;
    },
  },
  methods: {
    ...mapActions({
      show: 'projects/show',
    }),
  },
  async mounted() {
    await this.show(this.projectId);
    this.breadcrumbs = [
      {
        id: '1001',
        title: 'Home',
        to: 'dashboard',
      },
      {
        id: '1002',
        title: 'Projects',
        to: 'projects',
      },
      {
        id: '1003',
        title: this.address,
        to: { name: 'single-project', params: { id: this.projectId } },
      },
      {
        id: '1004',
        title: 'Archive',
      },
    ];
  },
};
</script>
