<template>
  <div class="breadcrumb breadcrumb-separatorless fw-medium fs-8 my-1 text-white">
    <px-new-breadcrumbs-item v-for="item in breadcrumbs" :key="item.id" :title="item.title" :to="item.to" />
  </div>
</template>

<script>
import PxNewBreadcrumbsItem from '@/components/elements/PxNewBreadcrumbsItem';

export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  components: { PxNewBreadcrumbsItem },
};
</script>
