var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative"},[(_vm.loader)?_c('px-loader',{attrs:{"is-full-window":false}}):_vm._e(),_c('px-new-table',{attrs:{"header":_vm.filteredHeader,"items":_vm.items,"pageInfo":_vm.pageInfo},on:{"filtered":_vm.filtered,"on-page-change":_vm.pageChange,"on-per-page-change":_vm.perPageChange},scopedSlots:_vm._u([{key:"scope_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body"},[_c('div',{staticClass:"symbol symbol-35px symbol-circle"},[_c('span',{class:[
              'symbol-label text-white fw-semi-bold',
              { 'bg-primary': item.attributes.scope_type === 'Swimming Pool' },
              { 'bg-success': item.attributes.scope_type === 'Landscaping' },
              { 'bg-warning': item.attributes.scope_type === 'Woodworking' },
              { 'bg-dark': !item.attributes.scope_type } ]},[_vm._v(" "+_vm._s(_vm.typeFirstLetter(item.attributes.scope_type))+" ")])])])]}},{key:"project_id",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"text-body text-hover-primary",attrs:{"to":{ name: 'proposal-preview', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.attributes.number)+" v"+_vm._s(item.attributes.version)+" ")])]}},{key:"address",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"text-body text-hover-primary",attrs:{"to":{ name: 'proposal-preview', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.attributes.subject.data.attributes.address)+" ")])]}},{key:"manager",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-body d-block"},[_vm._v(" "+_vm._s(_vm.managerFullName( item.attributes.subject.data.attributes.first_name, item.attributes.subject.data.attributes.last_name ))+" ")])]}},{key:"construction",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"text-body text-hover-primary",attrs:{"to":{ name: 'proposal-preview', params: { id: item.id } }}},[_vm._v(" "+_vm._s(_vm.scopeNameByParam(item.attributes.scope_type))+" ")])]}},{key:"version",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"badge badge-light fw-semi-bold fs-10"},[_vm._v("v"+_vm._s(item.attributes.version || '1'))])]}},{key:"created",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-body d-block"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.attributes.created_at,'MMM D, YYYY'))+" ")])]}},{key:"lastEdited",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-body d-block"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.attributes.updated_at,'MMM D, YYYY'))+" ")])]}},{key:"value",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-body d-block"},[_vm._v(_vm._s(_vm.$n(item.attributes.sub_total, 'currencyNonFraction', 'en-CA')))])]}},{key:"status",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"badge badge-light-primary fw-medium text-uppercase text-ls fs-10 me-auto px-3 py-2"},[_vm._v(" "+_vm._s(item.attributes.status || 'Pending')+" ")])]}},{key:"actions",fn:function(ref){
            var item = ref.item;
return [_c('px-dropdown',{attrs:{"items":[
          { title: 'View', action: function () { return _vm.goToPreview(item.id); } },
          { title: 'Edit', action: function () { return _vm.goToWizard(item.id); } } ]}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }