<template>
  <div class="position-relative">
    <px-loader v-if="loader" :is-full-window="false" />
    <px-new-table
      :header="filteredHeader"
      :items="items"
      :pageInfo="pageInfo"
      @filtered="filtered"
      @on-page-change="pageChange"
      @on-per-page-change="perPageChange"
    >
      <template slot="scope_type" slot-scope="{ item }">
        <div class="text-body">
          <div class="symbol symbol-35px symbol-circle">
            <span
              :class="[
                'symbol-label text-white fw-semi-bold',
                { 'bg-primary': item.attributes.scope_type === 'Swimming Pool' },
                { 'bg-success': item.attributes.scope_type === 'Landscaping' },
                { 'bg-warning': item.attributes.scope_type === 'Woodworking' },
                { 'bg-dark': !item.attributes.scope_type },
              ]"
            >
              {{ typeFirstLetter(item.attributes.scope_type) }}
            </span>
          </div>
        </div>
      </template>
      <template slot="project_id" slot-scope="{ item }">
        <router-link class="text-body text-hover-primary" :to="{ name: 'proposal-preview', params: { id: item.id } }">
          {{ item.attributes.number }}
          v{{ item.attributes.version }}
        </router-link>
      </template>
      <template slot="address" slot-scope="{ item }">
        <router-link class="text-body text-hover-primary" :to="{ name: 'proposal-preview', params: { id: item.id } }">
          {{ item.attributes.subject.data.attributes.address }}
        </router-link>
      </template>
      <template slot="manager" slot-scope="{ item }">
        <span class="text-body d-block">
          {{
            managerFullName(
              item.attributes.subject.data.attributes.first_name,
              item.attributes.subject.data.attributes.last_name
            )
          }}
        </span>
      </template>
      <template slot="construction" slot-scope="{ item }">
        <router-link class="text-body text-hover-primary" :to="{ name: 'proposal-preview', params: { id: item.id } }">
          {{ scopeNameByParam(item.attributes.scope_type) }}
        </router-link>
      </template>
      <template slot="version" slot-scope="{ item }">
        <span class="badge badge-light fw-semi-bold fs-10">v{{ item.attributes.version || '1' }}</span>
      </template>
      <template slot="created" slot-scope="{ item }">
        <span class="text-body d-block">
          {{ item.attributes.created_at | moment('MMM D, YYYY') }}
        </span>
      </template>
      <template slot="lastEdited" slot-scope="{ item }">
        <span class="text-body d-block">
          {{ item.attributes.updated_at | moment('MMM D, YYYY') }}
        </span>
      </template>
      <template slot="value" slot-scope="{ item }">
        <span class="text-body d-block">{{ $n(item.attributes.sub_total, 'currencyNonFraction', 'en-CA') }}</span>
      </template>
      <template slot="status" slot-scope="{ item }">
        <span class="badge badge-light-primary fw-medium text-uppercase text-ls fs-10 me-auto px-3 py-2">
          {{ item.attributes.status || 'Pending' }}
        </span>
      </template>
      <template slot="actions" slot-scope="{ item }">
        <px-dropdown
          :items="[
            { title: 'View', action: () => goToPreview(item.id) },
            { title: 'Edit', action: () => goToWizard(item.id) },
          ]"
        />
      </template>
    </px-new-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    excludedHeaders: {
      type: Array,
      default: () => [],
    },
    isArchived: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader: false,
      header: [
        {
          key: 'scope_type',
          title: 'Type',
          sort: false,
          class: 'w-40px',
        },
        {
          key: 'project_id',
          title: 'Project ID',
          sort: false,
        },
        {
          key: 'address',
          title: 'Address',
          sort: false,
        },
        {
          key: 'manager',
          title: 'Manager',
          sort: false,
        },
        {
          key: 'construction',
          title: 'Title',
          sort: false,
        },
        {
          key: 'version',
          title: 'Version',
          sort: false,
        },
        {
          key: 'created',
          title: 'Created',
          sort: false,
        },
        {
          key: 'lastEdited',
          title: 'Last edited',
          sort: false,
        },
        {
          key: 'value',
          title: 'Value',
          sort: false,
        },
        {
          key: 'status',
          title: 'Status',
          sort: true,
          sortKey: 'status',
          sortBy: 'desc',
        },
        {
          key: 'actions',
          title: 'Actions',
          sort: false,
          class: 'position-sticky top-0 end-0 bg-white text-end',
        },
      ],
      routeParams: {},
      queryParams: {},
      items: [],
      pageInfo: {},
    };
  },
  computed: {
    ...mapGetters({
      changeOrders: 'proposals/changeOrders',
    }),
    filteredHeader() {
      return this.header.filter((el) => {
        return !this.excludedHeaders.includes(el.title.toLowerCase());
      });
    },
  },
  async mounted() {
    this.routeParams = { subject_id: this.$route.params.id };
    this.queryParams = this.$route.query;

    await this.filtered({ page: 1, state: 'Change Order', archive: this.isArchived });
  },
  methods: {
    ...mapActions({
      index: 'proposals/initChangeOrders',
    }),
    managerFullName(name, surname) {
      return `${name} ${surname}`;
    },
    typeFirstLetter(type) {
      switch (type) {
        case 'Swimming Pool':
          return 'P';
        case 'Landscaping':
          return 'L';
        case 'Woodworking':
          return 'W';
        default:
          return 'N';
      }
    },
    async values() {
      this.pageInfo = this.changeOrders?.meta;
      this.items = this.changeOrders?.data;
    },
    async tableSort(head) {
      this.routeParams = { ...this.routeParams, order: head.sortBy };
      await this.filtered({ order: head.sortBy, order_by: head.key });
    },
    async filtered(data = {}) {
      this.loader = true;

      await this.index({ ...this.routeParams, ...this.queryParams, ...data });
      await this.values();
      await this.$emit('on-load', this.items.length);

      this.loader = false;
    },
    async pageChange(ind) {
      await this.filtered({ page: ind, state: 'Change Order', archive: this.isArchived });
    },
    async perPageChange(per) {
      await this.filtered({ per, state: 'Change Order', archive: this.isArchived });
    },
    goToPreview(id) {
      this.$router.push({ name: 'proposal-preview', params: { id } });
    },
    goToWizard(id) {
      this.$router.push({ name: 'proposal-wizard.pricing', params: { id } });
    },
  },
};
</script>
